import React, { useState } from "react";
import DataProvider from "hoc/data_provider";
import "./gallery_page.css";
import Layout from "components/layout/layout";
import { marked } from "helpers/helpers";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";

const GalleryPage = () => {
    const endPoint = `/web/gallery`;

    return <DataProvider endPoint={endPoint} component={GalleryComponent} />;
};

export default GalleryPage;

const GalleryComponent = ({ data }) => {
    const { categories, gallery } = data;

    const [currentCategory, setCurrentCategory] = useState("all");

    return (
        <Layout
            title="Photo Gallery"
            breadcrumb={[
                { name: "Home", path: "/" },
                {
                    name: "Gallery",
                },
            ]}
        >
            <section className="gallery-page">
                <ul className="categories">
                    <li
                        className="category"
                        onClick={() => setCurrentCategory("all")}
                    >
                        <p>
                            <b>All</b>
                        </p>
                    </li>
                    {categories.map((category) => {
                        return (
                            <li
                                className="category"
                                key={category}
                                onClick={() => setCurrentCategory(category)}
                            >
                                <p>
                                    <b>{category}</b>
                                </p>
                            </li>
                        );
                    })}
                </ul>
                <div className="gallery">
                    {gallery.map((image) => {
                        if (currentCategory === "all")
                            return <ImageContainer image={image} />;
                        else if (currentCategory === image.category)
                            return <ImageContainer image={image} />;
                        else return <></>;
                    })}
                </div>
            </section>
        </Layout>
    );
};

const ImageContainer = ({ image }) => {
    const src = `${axios.defaults.baseURL}/download/files/${image.files[0]}`;

    const [showCarousel, setShowCarousel] = useState(false);

    return (
        <div className="image-container" key={image.title}>
            <div className="image-wrapper">
                <img src={src} alt={image.title} className="gallery-image" />
                <div
                    className="image-description"
                    onClick={() => setShowCarousel(true)}
                >
                    <p dangerouslySetInnerHTML={marked(image.description)}></p>
                </div>
            </div>
            <h5 className="image-title">{image.title}</h5>
            {showCarousel && (
                <div className="image-carousel-wrapper">
                    <div className="image-carousel">
                        <div
                            className="header-btn"
                            onClick={() => setShowCarousel(false)}
                        >
                            close
                        </div>
                        <Carousel
                            className="img-gallery"
                            autoPlay={true}
                            infiniteLoop={true}
                            showThumbs={false}
                            swipeable={true}
                        >
                            {image.files.map((post) => {
                                return (
                                    <img
                                        src={`${axios.defaults.baseURL}/download/files/${post}`}
                                        alt={image.title}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                        }}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    );
};
