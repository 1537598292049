import React from "react";
import Layout from "components/layout/layout";
import ImageSlider from "components/imageslider/ImageSlider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const Library = () => {
    // Define the images to be used in the slider for this page
    const libraryImages = [
        '/assets/library/library1.jpg',
        '/assets/library/library2.jpg',
        '/assets/library/library3.jpg',
    ];

    return (
        <Layout
            title="Library"
            breadcrumb={[{ name: "Home", path: "/" }, { name: "Library" }]}
        >
            <div className="library-page">
                <div className="library-slider">
                    <ImageSlider images={libraryImages} />
                </div>
                <div className="library-content">
                    <p>
                        Government Engineering College, Bhavnagar has always been active in improving the library facility. Separate building for central library is available in more than 1000 square meter area with reading hall facilities as per the need of AICTE. It offers its service to about 2100 students of different branches of engineering and faculties from various departments of the institute. The books available in the library are related to technical courses and relevant to the subject matter of the respective branches. Patriotic storybooks & Competitive exam oriented books both in English and Gujarati languages are available in the library.
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default Library;

