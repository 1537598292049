import Layout from "components/layout/layout";
import InfoPage from "components/student_Section/info_page";
import StudentNav from "components/student_Section/studentNav";
import DataProvider from "hoc/data_provider";
import { useParams } from "react-router";

import "./student_section.css";
const StudentSection = () => {
    const { id, innerID } = useParams();
    return (
        <DataProvider
            endPoint="/web/student-section"
            component={StudentSectionComponent}
            id={id}
            innerID={innerID}
        />
    );
};
export default StudentSection;

const StudentSectionComponent = ({ data, id, innerID }) => {
    console.log(data);
    return (
        <div className="student-section">
            <StudentNav />
            <InfoPage data={data} id={id} innerID={innerID} />
        </div>
    );
};
