import React from "react";
import ContentLoader from "react-content-loader";

const NewsBoardLoader = (props) => (
    <div className="loader">
        <ContentLoader className="content-loader" speed={2} {...props}>
            <rect x="90" y="20" rx="0" ry="0" width="300" height="30" />
            <rect x="800" y="20" rx="0" ry="0" width="98" height="37" />
            <rect x="90" y="100" rx="0" ry="0" width="230" height="10" />
            <rect x="90" y="130" rx="0" ry="0" width="550" height="9" />
            <rect x="90" y="190" rx="0" ry="0" width="230" height="10" />
            <rect x="90" y="210" rx="0" ry="0" width="550" height="9" />
            <rect x="90" y="280" rx="0" ry="0" width="230" height="10" />
            <rect x="90" y="310" rx="0" ry="0" width="550" height="9" />
        </ContentLoader>
    </div>
);

export default NewsBoardLoader;
