import React from "react";
import { useParams } from "react-router";
import DataProvider from "hoc/data_provider";
import { marked } from "helpers/helpers";
import axios from "axios";

const VisitorDetails = () => {
    const { id } = useParams();

    const URL = `/web/visitors/${id}`;

    return <DataProvider endPoint={URL} component={Visitor} />;
};

export default VisitorDetails;

const Visitor = ({ data }) => {
    const { date, title, description, file } = data;

    let img;
    if (file) {
        img = `${axios.defaults.baseURL}/download/files/${file}`;
    }
    return (
        <div className="achievement-details-page">
            <div></div>

            <div className="details-pane column">
                <p className="date">
                    Published Date:&nbsp;
                    {new Date(date.substring(0, 10)).toDateString()}
                </p>
                <h1 className="title">{title}</h1>
                <div className="img">
                    {file ? (
                        <img src={img} alt="achivement-img" />
                    ) : (
                        <div
                            style={{
                                border: "solid 1px burlywood",
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            default image
                        </div>
                    )}
                </div>
                <p
                    className="description"
                    dangerouslySetInnerHTML={marked(description)}
                ></p>
            </div>
        </div>
    );
};
