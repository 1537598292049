import "./event_news_page.css";

import { useParams,useLocation } from "react-router-dom";
import DataProvider from "hoc/data_provider";
import { useEffect, useRef,useState } from "react";
import axios from "axios";

const DepartmentEventNewsPage = () => {
    const { type,department } = useParams();

    const endPoint = `/web/department/${type}/${department}`;

    return (
        <DataProvider endPoint={endPoint} component={EventNews} type={type} />
    );
};
export default DepartmentEventNewsPage;

const EventNews = ({ data, type }) => {
    const location = useLocation();
    const dept_name = location.state.name;
    const[time,setTime] = useState('present')
    return (
        <div className="event-news-page">
            <h1 className="header">{dept_name} {type.toUpperCase()}</h1>
            {type==="events"?(<div className="departments row">
                    <p
                        className="department-name"
                        id="current"
                        onClick={()=>setTime('present')}
                    >
                        Current {type.charAt(0).toUpperCase() + type.slice(1)}
                    </p>
                    <p
                        className="department-name"
                        id="past"
                        onClick={()=>setTime('past')}
                    >
                        Past {type.charAt(0).toUpperCase() + type.slice(1)}
                        </p>
                        </div>):null}

            <div className="content-list">
                {time==='present'?data.map((item, idx) => {
                    return <NewsEventTile key={idx} data={item} type={type}/>;
                }):data.map((item, idx) => {
                    return <PastNewsEventTile key={idx} data={item} type={type}/>;
                })}
            </div>
            
        </div>
    );
};

const NewsEventTile = ({ data,type }) => {
    const desc = useRef(null);
    var today = new Date();
    let link;
    if (data.file) {
        link = `${axios.defaults.baseURL}/download/files/${data.file}`;
    }

      useEffect(() => {
          if (desc && (Date.parse(data.date)>=today||type!=="events")) {
              desc.current.innerHTML = data.description;
          }
      }, []);
       if(Date.parse(data.date)>=today || type!=="events"){

    return (
        <div className="news-events column ">
            <div className="date">
                <p>{data.date.substring(0, 10)}</p>
            </div>
            <h2>{data.title}</h2>
            <p className="short-desc" ref={desc}></p>
            <a
                href={link}
                className="link"
                style={{ color: !link ? "grey" : null }}
            >
                Click Here
            </a>
        </div>
    );
      }
      else{
          return <p className="hidden"ref={desc} ></p>;
      }
};
const PastNewsEventTile = ({ data }) => {
    const desc = useRef(null);
    var today = new Date();
    let link;
    if (data.file) {
        link = `${axios.defaults.baseURL}/download/files/${data.file}`;
    }

      useEffect(() => {
          if (desc && Date.parse(data.date)<today) {
              desc.current.innerHTML = data.description;
          }
      }, []);
       if(Date.parse(data.date)<today){

    return (
        <div className="news-events column ">
            <div className="date">
                <p>{data.date.substring(0, 10)}</p>
            </div>
            <h2>{data.title}</h2>
            <p className="short-desc" ref={desc}></p>
            <a
                href={link}
                className="link"
                style={{ color: !link ? "grey" : null }}
            >
                Click Here
            </a>
        </div>
    );
      }
      else{
          return <p className="hidden"ref={desc} ></p>;
      }
};
