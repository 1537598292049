import "./faculty_profile.css";

import { useParams } from "react-router-dom";

import { facultyProfileItems } from "helpers/constants";
import DataProvider from "hoc/data_provider";
import CourseTaught from "./course_taught";
import EducationalQualification from "./ed_qu";
import PersonalDetails from "./personal_details";
import Portfolio from "./portfolio";
import Publications from "./publications";
import TrainingAndWorkshop from "./t_w";
import WorkExperience from "./work_exp";
import Layout from "components/layout/layout";
import StudyMaterial from "./StudyMaterial";

const FacultyProfilePage = () => {
    const { id } = useParams();
    const endPoint = `/web/faculties/${id}`;
    return <DataProvider component={FacultyProfile} endPoint={endPoint} />;
};

export default FacultyProfilePage;

const FacultyProfile = ({ data }) => {

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        try {
            element.scrollIntoView({
                behavior: "smooth",
            });
        } catch (error) {}
        // TODO:
        // not compatible with IE safari
        // change it if needed
    };

    const { first_name, last_name, prefix } = data.personal_details;

    const name = prefix + " " + first_name + " " + last_name;

    return (
        <Layout
            title={name}
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Faculties",
                    path: "/faculty",
                },
                {
                    name: name,
                },
            ]}
        >
            <div className="faculty-profile">
                <div className="buttons">
                    {facultyProfileItems.map((item, idx) => {
                        return (
                            <p
                                key={idx}
                                onClick={(e) => handleScroll(item.link)}
                            >
                                {item.title}
                            </p>
                        );
                    })}
                </div>
                <div className="faculty-details column">
                    <PersonalDetails data={data.personal_details} />
                    <EducationalQualification data={data.qualification} />
                    <Portfolio data={data.portfolio} />
                    <WorkExperience data={data.work_experience} />
                    <TrainingAndWorkshop data={data.training_workshop} />
                    <CourseTaught data={data.course_tought} />
                    <Publications data={data.publication} />
                    <StudyMaterial data={data.studymaterial} />
                </div>
            </div>
        </Layout>
    );
};
