import { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
    width: "100%",
    height: "350px",
    overflow: "hidden",
};

export class MapContainer extends Component {
    render() {
        return (
            <iframe style={mapStyles} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3705.527097912288!2d72.13049151477665!3d21.75985188560695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f508526704e0d%3A0xfd6803c3cfa8bf82!2sGovernment%20Engineering%20College%2C%20Bhavnagar!5e0!3m2!1sen!2sin!4v1666281227453!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAnaNsWxBoNif0Hm7Pf2xsQ1nQyad6lYng",
})(MapContainer);

