import React, { useEffect } from "react";
import ShowError from "components/error/error";
import ShowLoader from "components/loader/loader";
import useFetch from "hooks/useFetch";

const DataProvider = ({
    endPoint,
    component,
    loader,
    children,
    style,
    ...other
}) => {
    const { data, error, isPending, getData } = useFetch();

    useEffect(() => {
        getData(endPoint);
    }, []);

    if (!error) {
        if (!isPending) {
            return (
                <>
                    {React.createElement(component, { data, style, ...other })}
                    {children}
                </>
            );
        }
        return loader ? React.createElement(loader) : <ShowLoader />;
    }

    return (
        <div className="p">
            <ShowError error="error occurred" />
        </div>
    ); // TODO: add error component
};

export default DataProvider;
