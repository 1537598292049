import { useParams } from "react-router-dom";
import "./department_news.css";
import { NewsEventBox } from "components/home_page/digital_info/digital_info";
import DataProvider from "hoc/data_provider";
import NewsBoardLoader from "components/loader/NewsBoardLoader";

const DepartmentNews = () => {
    const { department } = useParams();
    const endPoint = `/web/department/news/${department}`;
    return (
        <DataProvider
            endPoint={endPoint}
            department={department}
            component={NewsEventBox}
            title="Latest News"
            icon="fas fa-newspaper"
            type="news"
            loader={NewsBoardLoader}
            style={{ maxWidth: "100%", minHeight: "400px", flex: "1" }}
        />
    );
};

export default DepartmentNews;
