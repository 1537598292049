import { useState } from "react";
import DataProvider from "hoc/data_provider";
import useMetadata from "hooks/useMetadata";
import { NavLink } from "react-router-dom";
import "./home_news.css";

const HomeNews = () => {
    const [divClass, setDivClass] = useState("hide-news");

    const toggleDivClass = () => {
        if (divClass === "hide-news") {
            setDivClass("show-news");
        } else {
            setDivClass("hide-news");
        }
    };

    const hideNews = () => {
        setDivClass("hide-news");
    };

    return (
        <div className={"home-news row r-c-x r-c-y " + divClass}>
            <div className="toggel-btn ">
                <div
                    className="btn-wrapper row r-c-x r-c-y"
                    onClick={toggleDivClass}
                >
                    <i className="las la-newspaper"></i>
                    <span>NEWS</span>
                </div>
            </div>
            <section className="news-section">
                <div className="header row r-c-y">
                    <section className="heading">
                        <h1>NEWS</h1>
                    </section>

                    <div className="icon-btn close" onClick={hideNews}>
                        <i className="las la-times"></i>
                    </div>
                </div>

                <div className="news-data column">
                    <DataProvider component={NewsData} endPoint="/web/news" />
                    <NavLink className="header-btn to-all" to={`/show/news`}>
                        See All
                    </NavLink>
                </div>
            </section>
        </div>
    );
};

export default HomeNews;

const NewsData = ({ data }) => {
    const newsData = data.slice(0, 10).map((news, idx) => {
        return <NewsRow news={news} key={idx} />;
    });

    return <ul>{newsData}</ul>;
};

const NewsRow = ({ news }) => {
    let endPoint = null;

    const { proxy } = useMetadata();

    if (news.file) {
        endPoint = `${proxy}/download/files/${news.file}`;
    }

    return (
        <li className="news-row row r-c-y">
            <div className="news-data-wrapper">
                <a href={endPoint} className="data">
                    {news.title}
                </a>
                <p className="date-time">{news.date.substring(0, 10)}</p>
            </div>
        </li>
    );
};
