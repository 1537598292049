import "./home_page.css";

import React from "react";
import Navbar from "components/navbar/navbar";
import FooterSection from "components/footer/footer";
import RenderRoutes from "routes/render_routes";
import ROUTES from "routes/routes";

const HomePage = () => {
    return (
        <div className="home-page">
            <Navbar />
            <RenderRoutes routes={ROUTES} />
            <FooterSection />
        </div>
    );
};

export default HomePage;
