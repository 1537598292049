import DataProvider from "hoc/data_provider";
import { marked } from "helpers/helpers";
import Layout from "components/layout/layout";

const Canteen = () => {
    const endPoint = `/web/other-cells/canteen`;

    return (
        <DataProvider component={Wysiwyg} endPoint={endPoint} key="canteen" />
    );
};

export default Canteen;

const Wysiwyg = ({ data, key }) => {
    return (
        <Layout
            title="Canteen"
            breadcrumb={[{ name: "Home", path: "/" }, { name: "Canteen" }]}
        >
            <div className="wysiwyg" key={key}>
                <div
                    id="content-data"
                    dangerouslySetInnerHTML={marked(data.data)}
                ></div>
            </div>
        </Layout>
    );
};
