import "./event_news_page.css";

import { useParams } from "react-router-dom";
import DataProvider from "hoc/data_provider";
import { useEffect, useRef, useState } from "react";
import useMetadata from "hooks/useMetadata";
import Layout from "components/layout/layout";
import axios from "axios";

const EventNewsPage = () => {
    const { type } = useParams();

    const endPoint = `/web/${type}`;
    return (
        <DataProvider endPoint={endPoint} component={EventNews} type={type} />
    );
};
export default EventNewsPage;

const EventNews = ({ data, type }) => {
    const [time, setTime] = useState("present");

    const handleFilter = (e) => {
        const current = document.getElementById("current");
        const past = document.getElementById("past");
        if (e.target.id === "current") {
            current.classList.add("active");
            past.classList.remove("active");
            setTime("present");
        } else {
            past.classList.add("active");
            current.classList.remove("active");
            setTime("past");
        }
    };

    return (
        <Layout
            title={type.toUpperCase()}
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: type,
                },
            ]}
        >
            <div className="event-news-page">
                {type === "events" ? (
                    <div className="departments row" id="event-filter">
                        <p
                            className="department-name active"
                            id="current"
                            onClick={(e) => handleFilter(e)}
                        >
                            Current{" "}
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </p>
                        <p
                            className="department-name"
                            id="past"
                            onClick={(e) => handleFilter(e)}
                        >
                            Past {type.charAt(0).toUpperCase() + type.slice(1)}
                        </p>
                    </div>
                ) : null}

                <div className="content-list">
                    {time === "present" || type !== "events"
                        ? data.map((item, idx) => {
                            return (
                                <NewsEventTile
                                    key={idx}
                                    data={item}
                                    type={type}
                                />
                            );
                        }) : data.map((item, idx) => {
                            return (
                                <PastNewsEventTile
                                    key={idx}
                                    data={item}
                                    type={type}
                                />
                            );
                        }
                    )}
                </div>
            </div>
        </Layout>
    );
};

const NewsEventTile = ({ data, type }) => {
    const desc = useRef(null);
    var today = new Date();

    const { proxy } = useMetadata();

    let link;
    if (data.file) {
        link = `${proxy}/download/files/${data.file}`;
    }

    const handleDownload = async () => {
        const pdfWindow = window.open();
        try {
            await fetch(link)
                .then((response) => {
                    response
                        .blob()
                        .then((blob) => {
                            const fileURL = URL.createObjectURL(blob);
                            pdfWindow.location.href = fileURL;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
    };

    useEffect(() => {
        if (desc && Date.parse(data.date) >= today) {
            desc.current.innerHTML = data.description;
        }
    }, []);
    if (Date.parse(data.date) >= today || type !== "events") {
        return (
            <div className="news-events column ">
                <div className="date">
                    <p>{data.date.substring(0, 10)}</p>
                </div>
                <h5>{data.title}</h5>
                <p className="short-desc" ref={desc}></p>
                {link && (
                    <div
                        onClick={handleDownload}
                        className="link header-btn"
                        style={{
                            color: !link ? "grey" : null,
                            cursor: link ? "pointer" : "auto",
                        }}
                    >
                        Click Here
                    </div>
                )}
            </div>
        );
    } else {
        return <p className="hidden" ref={desc}></p>;
    }
};
const PastNewsEventTile = ({ data }) => {
    const desc = useRef(null);
    var today = new Date();
    let link;
    if (data.file) {
        link = `${axios.defaults.baseURL}/download/files/${data.file}`;
    }

    useEffect(() => {
        if (desc && Date.parse(data.date) < today) {
            desc.current.innerHTML = data.description;
        }
    }, []);

    const handleDownload = async () => {
        const pdfWindow = window.open();
        try {
            await fetch(link)
                .then((response) => {
                    response
                        .blob()
                        .then((blob) => {
                            const fileURL = URL.createObjectURL(blob);
                            pdfWindow.location.href = fileURL;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
    };

    if (Date.parse(data.date) < today) {
        return (
            <div className="news-events column ">
                <div className="date">
                    <p>{data.date.substring(0, 10)}</p>
                </div>
                <h5>{data.title}</h5>
                <p className="short-desc" ref={desc}></p>
                {link && (
                    <div
                        onClick={handleDownload}
                        className="link header-btn"
                        style={{
                            color: !link ? "grey" : null,
                            cursor: link ? "pointer" : "auto",
                        }}
                    >
                        Click Here
                    </div>
                )}
            </div>
        );
    } else {
        return <p className="hidden" ref={desc}></p>;
    }
};
