import React, { useEffect, useRef } from "react";
import "./achievements.css";
import DataProvider from "hoc/data_provider";
import { NavLink } from "react-router-dom";
import Layout from "components/layout/layout";
import { marked } from "helpers/helpers";
import axios from "axios";

const AchievementsPage = () => {
    const endPoint = `/web/achievements/all`;
    return (
        <DataProvider
            component={({ data }) => {
                return (
                    <Layout
                        title="Achievements"
                        breadcrumb={[
                            {
                                name: "Home",
                                path: "/",
                            },
                            {
                                name: "Achievements",
                            },
                        ]}
                    >
                        <section className="achivements-contents">
                            {data.map((achievement, idx) => {
                                return (
                                    <AchievementBox
                                        key={idx}
                                        data={achievement}
                                    />
                                );
                            })}
                        </section>
                    </Layout>
                );
            }}
            endPoint={endPoint}
        />
    );
};

export default AchievementsPage;

const AchievementBox = ({ data }) => {
    const { title, description, file, _id } = data;

    let fileURL;
    if (file) fileURL = `${axios.defaults.baseURL}/download/files/${file}`;

    return (
        <div className="achievement__box">
            <div className="img">
                {file ? (
                    <img src={fileURL} alt="achivement" loading="lazy" />
                ) : (
                    <div
                        style={{
                            border: "solid 1px burlywood",
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        default image
                    </div>
                )}
            </div>
            <div className="achievement__details column">
                <h4 className="achievement__title">{title}</h4>
                <p
                    className="description"
                    dangerouslySetInnerHTML={marked(description)}
                ></p>
                <NavLink
                    to={`/achievements/${_id}`}
                    className="header-btn"
                    target="_blank"
                >
                    Read more
                </NavLink>
            </div>
        </div>
    );
};
