import sanitizeHtml from "sanitize-html";

const sanitizeOptions = {
    allowedTags: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "br",
        "b",
        "i",
        "u",
        "s",
        "em",
        "strong",
        "a",
        "ul",
        "ol",
        "li",
        "blockquote",
        "code",
        "pre",
        "img",
        "hr",
        "table",
        "thead",
        "tbody",
        "tr",
        "th",
        "td",
        "div",
        "span",
        "iframe",
    ],
    allowedAttributes: {
        a: ["href", "name", "target"],
        img: ["src"],
        pre: ["class"],
        li: ["class"],
        code: ["class"],
        iframe: ["src", "width", "height"],
    },
    transformTags: {
        a: (tagName, attribs) => {
            const href = attribs.href;
            if (href && href.indexOf("http") !== 0) {
                attribs.href = `http://${href}`;
            }
            return { tagName, attribs };
        },
        img: (tagName, attribs) => {
            if (attribs.src && attribs.src.indexOf("http") !== 0) {
                attribs.src = `http://${attribs.src}`;
            }
            return { tagName, attribs };
        },
        iframe: (tagName, attribs) => {
            if (attribs.src && attribs.src.indexOf("http") !== 0) {
                attribs.src = `http://${attribs.src}`;
            }
            attribs.width = "1200px";
            attribs.height = "550px";
            return { tagName, attribs };
        },
    },

    allowedSchemes: ["http", "https", "mailto"],
};

export const marked = (data) => {
    return {
        __html: sanitizeHtml(data, sanitizeOptions),
    };
};
