import { useParams } from "react-router-dom";
import "./department_news.css";
import { NewsEventBox } from "components/home_page/digital_info/digital_info";
import DataProvider from "hoc/data_provider";
import NewsBoardLoader from "components/loader/NewsBoardLoader";

const DepartmentEvents = () => {
    const { department } = useParams();
    const endPoint = `/web/department/events/${department}`;
    return (
        <DataProvider
            endPoint={endPoint}
            department={department}
            component={NewsEventBox}
            loader={NewsBoardLoader}
            title="Upcoming Events"
            icon="fas fa-calendar-day"
            type="events"
            style={{ maxWidth: "100%", minHeight: "400px", flex: "1" }}
        />
    );
};

export default DepartmentEvents;
