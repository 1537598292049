import React, { useEffect, useRef } from "react";
import Sidebar from "components/placement/sidebar/sidebar";
import DataProvider from "hoc/data_provider";
import "./placement-styles/placement-news.css";
import Layout from "components/layout/layout";
import Heading from "components/heading/Heading";
import useMetadata from "hooks/useMetadata";
import { marked } from "helpers/helpers";
const PlacementNews = () => {
    return (
        <DataProvider
            component={PlacementNewsComponent}
            endPoint="/web/placements/news"
        />
    );
};
export default PlacementNews;

const PlacementNewsComponent = ({ data }) => {
    return (
        <Layout
            title="Placement News"
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Placement News",
                },
            ]}
        >
            <div className="for-division">
                <div className="content">
                    <div className="sub-content">
                        <div className="placement-news">
                            {data.map((news) => {
                                return <Newsbar news={news} key={news._id} />;
                            })}
                        </div>
                    </div>
                </div>
                <Sidebar />
            </div>
        </Layout>
    );
};

const Newsbar = ({ news }) => {

    const { title, description, date } = news;


    let endPoint = null;
    const { proxy } = useMetadata();

    if (news.file) {
        endPoint = `${proxy}/download/files/${news.file}`;
    }


    return (
        <div className="news">
            <div className="news-date">
                <p>
                    <i className="fa fa-calendar"></i>
                    <strong>&nbsp;&nbsp;{date.substring(0, 10)}</strong>
                </p>
            </div>
            <div className="notice-title-discription">
                <a href={endPoint} className="data-row">

                    <p className="title" dangerouslySetInnerHTML={marked(title)}></p>
                    <p className="description" dangerouslySetInnerHTML={marked(description)}></p>
                </a>
            </div>
        </div>
    );
};
