import "./home_page.css";

import React from "react";

import HomeNews from "components/home_news/home_news";
import HomeNotice from "components/home_news/home_notice";

import AboutCollege from "components/home_page/about_college/about_college";
import CollegeInfo from "components/home_page/college_info/college_info";
import DigitalInfo from "components/home_page/digital_info/digital_info";

import DataProvider from "hoc/data_provider";
import AboutLoader from "components/loader/AboutLoader";

const MainContent = () => {
    return (
        <div className="main-content">
            <CollegeInfo />
            <div className="all-content">
                <DataProvider
                    endPoint="/web/basic-data"
                    component={AboutCollege}
                    loader={AboutLoader}
                />
                <DigitalInfo />
            </div>

            <HomeNews />
            <HomeNotice />
        </div>
    );
};

export default MainContent;
