import axios from "axios";

const ClubCard = ({ club }) => {
    const img = `${axios.defaults.baseURL}/download/files/${club.file}`;

    const { name, description, details } = club;

    return (
        <div className="club_card">
            <img src={img} alt="club-img" className="club_icon" />
            <p className="name">{name}</p>
            <p className="desc">{description}</p>
            <div className="socials_contain">
                <div className="sm_contain">
                    <a href="" name="club_slack" id="club_slack">
                        <img src="https://img.icons8.com/ios/50/000000/slack-new.png" />
                    </a>
                    <label htmlFor="club_slack">Slack</label>
                </div>
                <div className="sm_contain">
                    <a href="" name="club_insta" id="club_insta">
                        <img src="https://img.icons8.com/ios/50/000000/instagram-new--v1.png" />
                    </a>
                    <label htmlFor="club_insta">Instagram</label>
                </div>
            </div>
        </div>
    );
};

export default ClubCard;
