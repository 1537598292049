import Heading from "components/heading/Heading";

const Portfolio = ({ data }) => {
    return (
        <div className="portfolio details-section" id="portfolio">
            <Heading>Portfolio</Heading>

            <ul>
                {data.map((v, i) => {
                    return (
                        <li className="info-list-item" key={v}>
                            <p>{v}</p>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default Portfolio;
