import React, { useState } from "react";
import HostelNews from "./hostel-news";
import Sidebar from "../../components/infrastructure/sidebar/sidebar";
import DataProvider from "../../hoc/data_provider";
import Layout from "components/layout/layout";
import { marked } from "helpers/helpers";
import "./style.css";

const fields = ["About", "Fee Structure", "Hostel Rules"];// "Hostel Details"];

const Hostels = () => {
    return <DataProvider component={HostelComponent} endPoint="/web/hostel" />;
};
export default Hostels;

const HostelComponent = ({ data }) => {
    const [activeField, setActiveField] = useState("About");

    return (
        <Layout
            title="Hostel"
            breadcrumb={[{ name: "Home", path: "/" }, { name: "Hostel" }]}
        >
            <div className="hostel-page recruiters placement">
                <div className="for-division">
                    <div className="content" style={{ width: "100%" }}>
                        <div className="branches">
                            {fields.map((field, id) => {
                                return (
                                    <div
                                        key={id}
                                        className={
                                            activeField === field
                                                ? "branch active"
                                                : "branch"
                                        }
                                        onClick={() => setActiveField(field)}
                                    >
                                        {field}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            className={
                                activeField === "About"
                                    ? "about_hostel"
                                    : "hide"
                            }
                        >
                            <h2 className="sub-heading2">About</h2>
                            <div
                                dangerouslySetInnerHTML={marked(data.about)}
                            ></div>
                        </div>

                        <div
                            className={
                                activeField === "Fee Structure"
                                    ? "fee_structure"
                                    : "hide"
                            }
                        >
                            <h2 className="sub-heading2">Fee Structure</h2>
                            <div
                                dangerouslySetInnerHTML={marked(
                                    data.feesStructure
                                )}
                            ></div>
                        </div>
                        <div
                            className={
                                activeField === "Hostel Rules"
                                    ? "hostel_rules"
                                    : "hide"
                            }
                        >
                            <h2 className="sub-heading2">Hostel Rules</h2>
                            <div
                                dangerouslySetInnerHTML={marked(
                                    data.hostelRules
                                )}
                            ></div>
                        </div>
                        <div
                            className={
                                activeField === "Hostel Details"
                                    ? "hostel_details"
                                    : "hide"
                            }
                        >
                            <h2 className="sub-heading2">Hostel Details</h2>
                            <div
                                dangerouslySetInnerHTML={marked(
                                    data.hostelDetails
                                )}
                            ></div>
                        </div>
                        <div className="hostel_notice">
                            <h2 className="sub-heading2">Notices</h2>
                            <HostelNews />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
