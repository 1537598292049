import Layout from "components/layout/layout";
import { marked } from "helpers/helpers";
import DataProvider from "hoc/data_provider";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import "./alumini.css";

const AluminiAssociation = () => {
    return (
        <DataProvider
            endPoint="/web/aluminis/"
            component={AluminiAssociationComponent}
        />
    );
};

export default AluminiAssociation;

const AluminiAssociationComponent = ({ data }) => {
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const res = data.filter(
            (item) => item.type === "about-alumini-association"
        );
        setFilteredData(res);
    }, []);

    return (
        <Layout
            title="Alumni Association"
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Alumni Association",
                    path: "/alumini-association",
                },
            ]}
        >
            <div className="alumini-data-wrapper">
                {filteredData.map((item, index) => {
                    return (
                        <div key={item._id} className="alumini_item">
                            <p
                                dangerouslySetInnerHTML={marked(
                                    item.description
                                )}
                            ></p>
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
};
