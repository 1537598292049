import axios from "axios";
import { useState } from "react";

const useFetch = () => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    const getData = async (endPoint) => {
        await axios
            .get(endPoint)
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data.data);
                    setIsPending(false);
                } else {
                    throw new Error("Couldn't fetch the data");
                }
                return;
            })
            .catch((err) => {
                setError(err);
                return;
            });
    };

    return { data, isPending, error, getData };
};

export default useFetch;
