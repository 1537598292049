import React from "react";
import { Link } from "react-router-dom";
const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="head-strip">
                <i className="fa fa-desktop"></i> <p> Placements</p>
            </div>
            <div className="strip">
                <Link to="/placement/about">
                    <i className="fa fa-arrow-right"></i> Training & Placement
                    Cell
                </Link>
            </div>
            <div className="strip">
                <Link to="/placement/news">
                    <i className="fa fa-arrow-right"></i> Placement News
                </Link>
            </div>
            <div className="strip">
                <Link to="/placement/summary">
                    <i className="fa fa-arrow-right"></i> Summary (Year Wise)
                </Link>
            </div>
            <div className="strip">
                <Link to="/placement/placed-students">
                    <i className="fa fa-arrow-right"></i> Placed Students
                </Link>
            </div>
            <div className="strip">
                <Link to="/placement/recruiters">
                    <i className="fa fa-arrow-right"></i> Our Recruiters
                </Link>
            </div>
        </div>
    );
};

export default Sidebar;
