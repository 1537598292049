import "./visit_counter.css";

import React from "react";

const VisitCounter = ({ data }) => {
    return (
        <div className="visit-counter">
            <p>Total Visit : {data}</p>
        </div>
    );
};

export default VisitCounter;
