import React, { useEffect, useState } from "react";

import Sidebar from "components/placement/sidebar/sidebar";
import DataProvider from "hoc/data_provider";
import "./placement-styles/recruiters.css";
import Layout from "components/layout/layout";
import axios from "axios";

const branches = [
    "All",
    "Computer Engineering",
    "Information Technology",
    "Electronics & Communication Engineering",
    "Mechanical Engineering",
    "Civil Engineering",
    "Production Engineering",
    "Information and Communication Technology",
    "Electronics and Instrumentation Engineering"
];

const Recruiters = () => {
    return (
        <DataProvider
            component={RecruiterComponent}
            endPoint="/web/placements/recruiters"
        />
    );
};
export default Recruiters;

const RecruiterComponent = ({ data }) => {
    const [activeDepartment, setActiveDepartment] = useState("All");

    const [recs, setRecs] = useState([]);

    const calcStats = () => {
        let filteredRecs = data.filter((rec) => {
            if (activeDepartment === "All") {
                return true;
            }
            return rec.department === activeDepartment;
        });
        setRecs(filteredRecs);
    };

    useEffect(() => {
        calcStats();
    }, [activeDepartment]);

    return (
        <Layout
            title="Recruiters"
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Summary of Placements",
                },
            ]}
        >
            <div className="recruiters placement">
                <div className="for-division">
                    <div className="content">
	    {/* <div className="branches">
                            {branches.map((branch, id) => {
                                return (
                                    <div
                                        key={id}
                                        className={
                                            activeDepartment === branch
                                                ? "branch active"
                                                : "branch"
                                        }
                                        onClick={() =>
                                            setActiveDepartment(branch)
                                        }
                                    >
                                        {branch}
                                    </div>
                                );
                            })}
                        </div>*/}
                        <div className="companies">
                            {recs.map((rec) => (
                                <Company key={rec._id} company={rec} />
                            ))}
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>
        </Layout>
    );
};

const Company = ({ company }) => {
    let img;
    let link;
    if (company.image) {
        img = `${axios.defaults.baseURL}/download/profiles/${company.image}`;
    }
    if (company.link) {
        link = `https://${company.link}`;
    }

    return (
        <div className="company">
            <a href={link} target="_blank" rel="noreferrer">
                <img src={img} alt={company.name} />
                <h2>{company.name}</h2>
            </a>
        </div>
    );
};
