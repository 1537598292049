import "./team.css";

const TeamCard = ({ info }) => {
  return (
    <div className="card">
      <h2 className="member_name">{info.name}</h2>
      <p className="member_desc">
        {info.dep} <br /> Batch of {info.batch}
      </p>
      <img src={info.github + ".png"} alt="" className="member_img" />
      <div className="socials_contain">
        <a href={info.twitter}>
          <img src="https://img.icons8.com/ios/50/000000/twitter--v1.png" />
        </a>
        <a href={info.insta}>
          <img src="https://img.icons8.com/ios/50/000000/instagram-new--v1.png" />
        </a>
        <a href={info.linkedin}>
          <img src="https://img.icons8.com/ios/50/000000/linkedin.png" />
        </a>
        <a href={info.github}>
          <img src="https://img.icons8.com/ios/50/000000/github--v1.png" />
        </a>
      </div>
    </div>
  );
};

export default TeamCard;
