import React from "react";
import "./Heading.css";

const Heading = ({ text, as, children, ...other }) => {
    return (
        <div className="heading-title" {...other}>
            <h2 className="title-bold">{children ? children : text}</h2>
        </div>
    );
};

export default Heading;
