import React, { useEffect, useState } from "react";

import maleImg from "../../assets/male.jpg"
import femaleImg from "../../assets/female.jpg"


import "./placement-styles/placed-students.css";

import Sidebar from "components/placement/sidebar/sidebar";
import DataProvider from "hoc/data_provider";
import Layout from "components/layout/layout";

const cards = [
    {
        icon: "fa fa-user",
        title: "Registered Students",
        value: "rs",
    },
    {
        icon: "fa fa-briefcase",
        title: "Placed Students",
        value: "ps",
    },
    {
        icon: "fa fa-building",
        title: "Placement Rate",
        value: "pr",
    },
    {
        icon: "fa fa-user",
        title: "Highest Package",
        value: "hp",
    },
    {
        icon: "fa fa-briefcase",
        title: "Average Package",
        value: "ap",
    },
    {
        icon: "fa fa-building",
        title: "Companies Visited",
        value: "cv",
    },
];

const branches = [
    "Computer Engineering",
    "Information Technology",
    "Electronics & Communication Engineering",
    "Mechanical Engineering",
    "Civil Engineering",
    "Production Engineering",
    "Information and Communication Technology",
    "Electronics and Instrumentation Engineering"
];

const PlacedStudents = () => {
    return (
        <DataProvider
            component={Students}
            endPoint="/web/placements/students"
        />
    );
};
export default PlacedStudents;

const Students = ({ data }) => {
    const { summaries, students } = data;
    const currentYear = new Date().getFullYear();
    //const previousYear = (currentYear-1).toString();
    const [isActive, setIsActive] = useState((currentYear - 1).toString());

    const [activeDepartment, setActiveDepartment] = useState("Computer Engineering");
    let mx = 0;
    summaries.forEach((summary) => {
        mx = Math.max(mx, parseInt(summary.year));
    });
    const [sums, setSums] = useState({
        rs: 0,
        ps: 0,
        pr: 0,
        hp: 0,
        ap: 0,
        cv: 0,
    });
    const [studs, setStuds] = useState([]);

    const calcStats = () => {
        let filteredSums = summaries.filter(
            (sum) =>
                sum.year === isActive && sum.departmentName === activeDepartment
        );
        let filteredStuds = students.filter(
            (std) =>
                std.department === activeDepartment &&
                std.batchYear === isActive
        );        
        filteredStuds.sort((a, b) => {
            return b.ctc - a.ctc;
        });
        if (filteredSums.length > 0) {
            const sum = filteredSums[0];
            let d = {
                rs: sum.registeredStudents,
                ps: sum.placedStudents,
                pr: ((parseInt(sum.placedStudents) / parseInt(sum.registeredStudents)) * 100).toFixed(2) + "%",
                hp: sum.highestPackage + " Lacs",
                ap: sum.averagePackage + " Lacs",
                cv: sum.companies,
            };
            setSums(d);
        } else {
            setSums({
                rs: 0,
                ps: 0,
                pr: 0,
                hp: 0,
                ap: 0,
                cv: 0,
            });
        }
        setStuds(filteredStuds);
    };

    useEffect(() => {
        calcStats();
    }, [isActive, activeDepartment]);

    return (
        <Layout
            title="Placed Students"
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Placed Students",
                },
            ]}
        >
            <div className="placed-students placement">
                <div className="for-division">
                    <div className="content">
                        <div className="years">
                            {Array.from({ length: 3 }, (i, idx) => {
                                return (
                                    <div
                                        className={
                                            parseInt(isActive) === mx - idx
                                                ? "active year"
                                                : "year"
                                        }
                                        onClick={() =>
                                            setIsActive((mx - idx).toString())
                                        }
                                    >
                                        {mx - idx}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="branches">
                            {branches.map((branch, id) => {
                                return (
                                    <div
                                        key={id}
                                        className={
                                            activeDepartment === branch
                                                ? "branch active"
                                                : "branch"
                                        }
                                        onClick={() =>
                                            setActiveDepartment(branch)
                                        }
                                    >
                                        {branch}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="cards">
                            {
                                (sums.ps > 0) ? cards.map((card, id) => {
                                    return (
                                        <DataCard
                                            key={id}
                                            title={card.title}
                                            icon={card.icon}
                                            value={sums[card.value]}
                                        />
                                    );
                                })
                                    : <div className="no-data" >No Data Available</div>
                            }
                        </div>
                        <div className="profile-cards">
                            {studs.map((std) => {
                                return <ProfileCard std={std} key={std._id} />;
                            })}
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>
        </Layout>
    );
};

const DataCard = ({ icon, title, value }) => {
    return (
        <div className="data-card">
            <div className="card-icon">
                <i className={icon}></i>
            </div>
            <div className="card-info">
                <strong>{value}</strong>
                <p>{title}</p>
            </div>
        </div>
    );
};

const ProfileCard = ({ std }) => {
    let img;
    // if (std.image) {
    //     img = `${axios.defaults.baseURL}/download/profiles/${std.image}`;
    // }
    return (
        <div className="profile-card-grid">
            <div className="profile-card">
                <div className="student-image">
                    {std.gender == "M" ? (<img src={maleImg} alt={std.name} />) : (<img src={femaleImg} alt={std.name} />)}
                </div>
                <div className="student-info">
                    <h5>{std.name}</h5>
                    <p className="en-number">{std.company}</p>
                    <p className="ctc">CTC</p>
                    <h4 className="paisa">
                        {std.ctc + " Lacs"}
                    </h4>
                </div>
            </div>
        </div>

    );
};
