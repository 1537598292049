const navbarItems = [
    {
        title: "Home",
        link: "/",
    },
    {
        title: "About",
        dropdown: [
            { title: "About College", link: "/about/college" },
            { title: "College Campus", link: "/about/college-campues" },
            // { title: "Placment Cell", link: "/about/pc" },
            { title: "Women Cell", link: "/about/women-cell" },
            { title: "Grievance Cell", link: "/about/grievance-cell" },
            { title: "Anti Ragging", link: "/about/anti-ragging" },
            { title: "Student Startup & Innovation", link: "/about/ssip" },
            { title: "RTI", link: "/about/rti" },
            { title: "Newsletter", link: "/about/news-letter" },
		{title: "Cell for Specially Abled", link:"/about/csa"},
		{title: "Information Brochure", link: "/about/information_brochure"},
        ],
    },
    {
        title: "Department",
        dropdown: [
            {
                title: "CIVIL ENGINEERING",
                link: "/departments/ci.e",
            },
            {
                title: "COMPUTER ENGINEERING",
                link: "/departments/c.e",
            },
            {
                title: "INFORMATION TECHNOLOGY",
                link: "/departments/i.t",
            },
            {
                title: "ELECTRONICS & COMM. ENGG.",
                link: "/departments/e&c.e",
            },
            {
                title: "MECHANICAL ENGINEERING ",
                link: "/departments/m.e",
            },
            {
                title: "PRODUCTION ENGINEERING",
                link: "/departments/p.e",
            },
            {
                title:"INFORMATION AND COMMUNICATION TECHNOLOGY",
                link:"/departments/ict"
            },
            //{
            //    title:"ELECTRONICS AND INSTRUMENTATION ENGINEERING",
            //    link:"/departments/eie"
            //},
            {
                title: "APPLIED MECHANICS",
                link: "/departments/a.m",
            },
            // {
            //     title: "Center of Excellence",
            //     link: "/departments/co.e",
            // },
            { title: "HUMANITIES AND SCIENCE", link: "/departments/genral" },
        ],
    },
    {
        title: "People",
        dropdown: [
            { title: "Faculty", link: "/faculty" },
            { title: "Staff", link: "/staff" },
        ],
    },
    {
        title: "Placement",
        dropdown: [
            {
                title: "Training & Placement cell",
                link: "/placement/about",
            },
            {
                title: "Placement News",
                link: "/placement/news",
            },
            {
                title: "Summary (Year-wise)",
                link: "/placement/summary",
            },
            {
                title: "Placed Students",
                link: "/placement/placed-students",
            },
            {
                title: "Our Recruiters",
                link: "/placement/recruiters",
            },
        ],
    },
    {
        title: "Infrastructure",
        dropdown: [
            {
                title: "Hostel",
                link: "/infrastructure/hostel",
            },
            {
                title: "Library",
                link: "/infrastructure/library",
            },
	    {
                title: "CoE",
                link: "/infrastructure/coe",
            },
           // {
           //     title: "Canteen",
           //     link: "/infrastructure/canteen",
           // },
        ],
    },
    {
        title: "Alumni",
        dropdown: [
            {
                title: "About Alumni Association",
                link: "/alumini/association",
            },
            {
                title: "Trust Details",
                link: "/alumini/alumini-trust-details",
            },
            {
                title: "Coordinator Details",
                link: "/alumini/alumini-coordinator-details",
            },
        ],
    },
    {
        title: "Others",
        dropdown: [
            {
                title: "Achievements",
                link: "/achievements",
            },
            {
                title: "Clubs",
                link: "/clubs",
            },
            {
                title: "Gallery",
                link: "/gallery",
            },
           // {
           //     title: "Visitors",
           //     link: "/visitors",
           // },
            {
                title: "Student Section",
                link: "/student-section/home/all",
            },
            {
                title: "NCC",
                link: "/others/ncc",
            },
            {
                title: "NSS",
                link: "/others/nss",
            },
            {
                title: "IQAC",
                link: "/others/iqac",
            },
		{
			title:"I I P",
			link:"/others/iip",
		},
            {
                title: "Skill Empowerment",
                link: "/others/skill-empowerment",
            },
        ],
    },
    {
        title: "NIRF",
        link: "/others/nirf",
    },
    { title: "Contact Us", link: "/contact-us" },
];

const facultyProfileItems = [
    { title: "Personal Details", link: "pd" },
    { title: "Educational Qualification", link: "ed-qu" },
    { title: "Portfolio", link: "portfolio" },
    { title: "Work Experience", link: "work" },
    { title: "Training and Workshop", link: "t_w" },
    { title: "Course Taught", link: "course" },
    { title: "Publications", link: "publications" },
    { title: "Study Material", link: "study_materials" }
];
const studentSectionItems = [
    { title: "Home", link: "/student-section/home/all" },
    {
        title: "Admissions",
        dropdown: [
            {
                title: "First Year Admissions",
                link: "/student-section/admission/first-year-admissions",
            },
            {
                title: "D2D Admissions",
                link: "/student-section/admission/d2d-admissions",
            },
            {
                title: "Admission Cancellation",
                link: "/student-section/admission/admission-cancellation",
            },
        ],
    },
    {
        title: "Scholarships",
        dropdown: [
            {
                title: "Digital Gujarat",
                link: "/student-section/scholarships/digital-gujarat",
            },
            { title: "MYSY", link: "/student-section/scholarships/mysy" },
            { title: "NSP", link: "/student-section/scholarships/nsp" },
        ],
    },
    {
        title: "GTU Related",
        dropdown: [
            {
                title: "Name Correction",
                link: "/student-section/gtu-related/name-correction",
            },
            {
                title: "College Transfer",
                link: "/student-section/gtu-related/college-transfer",
            },
            {
                title: "Recheck & Reassessment",
                link: "/student-section/gtu-related/recheck-&-reassessment",
            },
        ],
    },
    {
        title: "Forms and Formats",
        link: "/student-section/forms-formats/all",
    },
    { title: "Fees", link: "/student-section/fees/all" },
];
const wysiwygTitles = {
    college: "About College",
    "college-campues": "College Campus",
    pc: "Placement Cell",
    "women-cell": "Women Cell",
    "grievance-cell": "Grievance Cell",
    "anti-ragging": "Anti Ragging",
    ssip: "Student Startup & Innovation",
    rti: "RTI",
    "news-letter": "Newsletter",
    ncc: "NCC",
    nss: "NSS",
    iqac: "IQAC",
	nirf: "NIRF",
	csa: "Cell for Specially Abled",
	information_brochure:"Information Brochure",
    "skill-empowerment": "Skill Empowerment Cell",
    iip:"Industry Institute Partenership"
};

export { navbarItems, facultyProfileItems, studentSectionItems, wysiwygTitles };
