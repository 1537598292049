import { marked } from "helpers/helpers";
import React from "react";
import useMetadata from "hooks/useMetadata";
import DataProvider from "../../hoc/data_provider";
import "../placement/placement-styles/placement-news.css";
const HostelNews = () => {
    return (
        <DataProvider
            component={HostelNewsComponent}
            endPoint="/web/hostel/notices"
        />
    );
};
export default HostelNews;

const HostelNewsComponent = ({ data }) => {
    return (
        <div className="placement-news placement">
            <div className="all-news">
                {
                    data.length > 0 ? (data.map((news) => {
                        return <Newsbar news={news} key={news._id} />;
                    })) : (<div className="no-data">No new notice as of now...</div>)
                }
            </div>
        </div>
    );
};

const Newsbar = ({ news }) => {

    const { title, description, date } = news;

    let endPoint = null;
    const { proxy } = useMetadata();

    if (news.file) {
        endPoint = `${proxy}/download/files/${news.file}`;
    }


    return (
        <div className="news">
            <div className="news-date">
                <p>
                    <i className="fa fa-calendar"></i>
                    <strong>&nbsp;{date.substring(0, 10)}</strong>
                </p>
            </div>
            <div className="notice-title-discription">
                <a href={endPoint} className="data-row">
                    <p className="title" dangerouslySetInnerHTML={marked(title)}></p>
                    <p className="description" dangerouslySetInnerHTML={marked(description)}></p>
                </a>
            </div>
        </div>
    );
};
