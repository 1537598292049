import "./department_page.css";
import { Carousel } from "react-responsive-carousel";
import { useParams, Link } from "react-router-dom";

import DataProvider from "hoc/data_provider";
import DepartmentNews from "./department_news";
import DepartmentEvents from "./department_events";
import { marked } from "helpers/helpers";
import Layout from "components/layout/layout";
import axios from "axios";

import { useRef } from "react";

const DepartmentPage = () => {
    const { department } = useParams();
    const endPoint = `/web/departments/${department}`;

    return (
        <DataProvider
            key={department}
            endPoint={endPoint}
            component={Department}
        />
    );
};

export default DepartmentPage;

const Department = ({ data }) => {
    const sidebar = useRef(null);
    const { department, labs } = data;

    let keys = {
        "COMPUTER ENGINEERING": "c.e",
        "CIVIL ENGINEERING": "ci.e",
        "INFORMATION TECHNOLOGY": "i.t",
        "ELECTRONICS AND COMMUNICATION ENGINEERING": "e&c.e",
        "MECHANICAL ENGINEERING": "m.e",
        "PRODUCTION ENGINEERING": "p.e",
        "APPLIED MECHANICS": "a.m",
        "Center of Excellence": "co.e",
        "INFORMATION AND COMMUNICATION TECHNOLOGY": "ict",
        "ELECTRONICS AND INSTRUMENTATION ENGINEERING": "eie",
        "HUMANITIES AND SCIENCE DEPARTMENT": "has",  
    }
    
    const { name, mission, message, vision, peo, pso } = department;

    return (
        <Layout
            title={name}
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: name,
                },
            ]}
        >
            <div className="department-page">
                <div className="department-page-data">
                    <div className="department-info" id="info">
                        <div className="department-vision">
                            <h3 className="department-info-title">Vision</h3>
                            <p
                                className="department-desc"
                                dangerouslySetInnerHTML={marked(vision)}
                            ></p>
                        </div>

                        <div className="department-mission">
                            <h3 className="department-info-title">Mission</h3>
                            <p
                                className="department-desc"
                                dangerouslySetInnerHTML={marked(mission)}
                            ></p>
                        </div>

	    		<div className="department-peo">
                            <h3 className="department-info-title">PEOs</h3>
                            <p
                                className="department-desc"
                                dangerouslySetInnerHTML={marked(peo)}
                            ></p>
                        </div>

			<div className="department-pso">
                            <h3 className="department-info-title">PSOs</h3>
                            <p
                                className="department-desc"
                                dangerouslySetInnerHTML={marked(pso)}
                            ></p>
                        </div>

                        <div className="department-message">
                            <h3 className="department-info-title">
                                HoD's Message
                            </h3>
                            <p
                                className="department-desc"
                                dangerouslySetInnerHTML={marked(message)}
                            ></p>
                        </div>
                    </div>
                </div>
                <div
                    className="department-sidebar-wrapper"
                    ref={sidebar}
                    id="sidebar-wrapper"
                >
                    <div className="department-sidebar">
                        <ul className="sidebar">
                            <li className="head-strip">
                                <p> {name}</p>
                            </li>
                            <li className="strip">
                                <a href="#info">
                                    <i className="fa fa-arrow-right"></i>{" "}
                                    Information
                                </a>
                            </li>
                            <li className="strip">
                                <a href="#news">
                                    <i className="fa fa-arrow-right"></i> News
                                </a>
                            </li>
                            <li className="strip">
                                <a href="#events">
                                    <i className="fa fa-arrow-right"></i> Events
                                </a>
                            </li>
                            <li className="strip">
                                <a href={`https://gecbhavnagar.ac.in/faculty/${keys[department.name]}`} target="_blank">
                                    <i className="fa fa-arrow-right"></i>{" "}
                                    Faculties
                                </a>
                            </li>
                            <li className="strip">
                                <a href="#labs">
                                    <i className="fa fa-arrow-right"></i> Labs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="department-news-events">
                    <div id="news">
                        <DepartmentNews />
                    </div>

                    <div id="events">
                        <DepartmentEvents />
                    </div>
                </div>

                <div className="department-labs" id="labs">
                    <h3 className="department-info-title">Laboratories </h3>
                    <div className="department-labs-list">
                        {labs.map((lab) => {
                            return <LabCard key={lab._id} lab={lab} />;
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const LabCard = ({ lab }) => {
    const { title, description, images } = lab;

    return (
        <div className="lab-card">
            <Carousel
                className="lab-gallery"
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showIndicators={false}
            >
                {images.map((image, idx) => {
                    const img = `${axios.defaults.baseURL}/download/files/${image}`;
                    return (
                        <div className="lab_image" key={idx}>
                            <img src={img} alt="lab-imgs" />
                        </div>
                    );
                })}
            </Carousel>
            <div className="lab-info">
                <h5 className="lab-title">{title}</h5>
                <p className="lab-desc">{description}</p>
            </div>
        </div>
    );
};
