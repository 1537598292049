import Heading from "components/heading/Heading";

const Publications = ({ data }) => {
    return (
        <div className="publications details-section" id="publications">
            <Heading>Publications</Heading>
            <div className="information">
                <ol>
                    {data.map((v, i) => {
                        return (
                            <li className="info-list-item" key={v}>
                                <p>{v}</p>
                            </li>
                        );
                    })}
                </ol>
            </div>
        </div>
    );
};
export default Publications;
