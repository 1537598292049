import { BrowserRouter, Route, Switch } from "react-router-dom";

import StudentSection from "pages/student_section/student_section";

import HomePage from "pages/home_page/home_page";
import NavScrollToTop from "hooks/useScrollToTop";
import ScrollToTop from "components/scroll_to_top/ScrollToTop";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <NavScrollToTop />
                <Switch>
                    <Route
                        path="/student-section/:id/:innerID"
                        component={StudentSection}
                    />
                    <HomePage />
                </Switch>
                <ScrollToTop />
            </div>
        </BrowserRouter>
    );
}

export default App;
