import "./undraw_profile_pic_ic5t.svg";
import "./all_faculties.css";

import DataProvider from "hoc/data_provider";

import React, { useState, useEffect } from "react";
import FacultyLoader from "components/loader/FacultyLoader";
import Layout from "components/layout/layout";
import axios from "axios";

const staffs = [
  {
    key: "All",
    name: "All Staff",
  },
  {
    key: "COMPUTER ENGINEERING",
    name: "Computer Engineering",
  },
  {
    key: "INFORMATION TECHNOLOGY",
    name: "Information Technology",
  },
  {
    key: "ELECTRONICS & COMM. ENGG.",
    name: "Electronics & Communication Engineering",
  },
  {
    key: "CIVIL ENGINEERING",
    name: "Civil Engineering",
  },
  {
    key: "MECHANICAL ENGINEERING",
    name: "Mechanical Engineering",
  },
  {
    key: "PRODUCTION ENGINEERING",
    name: "Production Engineering",
  },
  {
    key: "APPLIED MECHANICS",
    name: "Applied Mechanics",
  },
  {
    key: "CENTER OF EXCELLENCE",
    name: "Center Of Excellence",
  },
  {
    key: "HUMANITIES AND SCIENCE",
    name: "has",
  },
  {
    key: "ESTABLIISMENT",
    name: "Establishment",
  },
  {
    key: "ACCOUNT",
    name: "Account",
  },
  {
    key: "ADMINISTRATIVE",
    name: "Administrative",
  },
];

const StaffPage = () => {
  return (
    <DataProvider
      component={Staff}
      endPoint="/web/staff"
      loader={FacultyLoader}
    />
  );
};
export default StaffPage;

const Staff = ({ data }) => {
  const [filteredFaculty, setFaculty] = useState(data);

  const [activeDepartment, setActiveDepartment] = useState("All");

  const calcStats = () => {
    let filteredFaclt = data.filter((filteredFaculty) => {
      if (activeDepartment === "All") {
        return true;
      }
      return filteredFaculty.department_name === activeDepartment;
    });
    setFaculty(filteredFaclt);
  };

  useEffect(() => {
    calcStats();
  }, [activeDepartment]);

  return (
    <Layout
      title="Staffs"
      breadcrumb={[
        { name: "Home", path: "/" },
        {
          name: "Staffs",
        },
      ]}
    >
      <div className="all-faculties-page">
        <div className="departments row">
          {staffs.map((staff, id) => {
            return (
              <p
                key={id}
                className={
                  activeDepartment === staff.key
                    ? "department-name active"
                    : "department-name"
                }
                onClick={() => setActiveDepartment(staff.key)}
              >
                {staff.name}
              </p>
            );
          })}
        </div>

        {/* <div className="departments row">
    <p
        className={
            active === "faculty"
                ? "department-name active"
                : "department-name"
        }
        id="faculty"
        onClick={filter}
    >
        All Faculties
    </p>
    <p
        className={
            active === "computer"
                ? "department-name active"
                : "department-name"
        }
        id="computer"
        onClick={filter}
    >
        Computer Engineering
    </p>
    <p
        className={
            active === "information"
                ? "department-name active"
                : "department-name"
        }
        id="information"
        onClick={filter}
    >
        Information Technology
    </p>
    <p
        className={
            active === "electronics"
                ? "department-name active"
                : "department-name"
        }
        id="electronics"
        onClick={filter}
    >
        Electronics & Communication Engineering
    </p>
    <p
        className={
            active === "mechanical"
                ? "department-name active"
                : "department-name"
        }
        id="mechanical"
        onClick={filter}
    >
        Mechanical Engineering
    </p>{" "}
    <p
        className={
            active === "civil"
                ? "department-name active"
                : "department-name"
        }
        id="civil"
        onClick={filter}
    >
        Civil Engineering
    </p>
    <p
        className={
            active === "production"
                ? "department-name active"
                : "department-name"
        }
        id="production"
        onClick={filter}
    >
        Production Engineering
    </p>
    <p
        className={
            active === "AM"
                ? "department-name active"
                : "department-name"
        }
        id="applied"
        onClick={filter}
    >
        Applied Mechanics
    </p>
    <p
        className={
            active === "center"
                ? "department-name active"
                : "department-name"
        }
        id="center"
        onClick={filter}
    >
        Center of Excellence
    </p>
    <p
        className={
            active === "general"
                ? "department-name active"
                : "department-name"
        }
        id="general"
        onClick={filter}
    >
        General
    </p>
</div> */}

        <div className="faculties row r-c-x">
          {filteredFaculty.map((faculty, idx) => {
            return <FacultyCard key={idx} faculty={faculty} />;
          })}
        </div>
      </div>
    </Layout>
  );
};

const FacultyCard = ({ faculty }) => {
  const { first_name, last_name, prefix, image } = faculty;

  const { staffType, _id, department_name } = faculty;

  const name = prefix + " " + first_name + " " + last_name;
  let img;
  if (image) {
    img = `${axios.defaults.baseURL}/download/profiles/${image}`;
  }

  return (
    <div className="faculty">
      <div className="image">
        <img src={img} alt="profile" loading="lazy" />
      </div>
      <div className="data column c-c-y">
        <p className="name">{name}</p>
        {/* <p className="title">
                        phD(university of undraw at knoxville)
                    </p> */}

        <div className="branch">
          <strong>{department_name.toUpperCase()}</strong>
        </div>
      </div>
    </div>
  );
};
