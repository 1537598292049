import React from "react";
import "./layout.css";

import { Link } from "react-router-dom";

const Layout = ({ title, children, breadcrumb = [] }) => {
    return (
        <div className="layout-wrapper">
            <div className="layout-header">
                <div className="layout-title-wrapper">
                    <h2 className="layout-title">{title}</h2>
                    <div className="breadcrumb-wrapper">
                        <ul className="breadcrumb-list">
                            {breadcrumb.map((item, index) => {
                                return (
                                    <li className="breadcrumb-item" key={index}>
                                        {index === breadcrumb.length - 1 ? (
                                            <span>{title}</span>
                                        ) : (
                                            <Link to={item.path}>
                                                {item.name}
                                            </Link>
                                        )}

                                        {index !== breadcrumb.length - 1 && (
                                            <span className="breadcrumb-separator">
                                                <i className="fas fa-chevron-right"></i>
                                            </span>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="layout-content">{children}</div>
        </div>
    );
};

export default Layout;
