import { useParams } from "react-router-dom";
import { wysiwygTitles } from "helpers/constants";

import DataProvider from "hoc/data_provider";

import "./wysiwyg.css";
import { marked } from "helpers/helpers";
import Layout from "components/layout/layout";

const WysiwygPage = () => {
    const { id } = useParams();

    const endPoint = `/web/other-cells/${id}`;

    return (
        <DataProvider
            component={Wysiwyg}
            endPoint={endPoint}
            key={id}
            title={id}
        />
    );
};

export default WysiwygPage;

const Wysiwyg = ({ data, title, key }) => {
    return (
        <Layout
            title={wysiwygTitles[title]}
            breadcrumb={[
                { name: "Home", path: "/" },
                { name: wysiwygTitles[title] },
            ]}
        >
            <div className="wysiwyg" key={key}>
                <div
                    id="content-data"
                    dangerouslySetInnerHTML={marked(data.data)}
                ></div>
            </div>
        </Layout>
    );
};
