import Heading from "components/heading/Heading";
import axios from "axios";

const PersonalDetails = ({ data }) => {
    const {
        first_name,
        last_name,
        prefix,
        area_of_interest,
        qualification,
        designation,
        image,
        experience,
    } = data;

    const name = prefix + " " + first_name + " " + last_name;
    const img = `${axios.defaults.baseURL}/download/profiles/${image}`;

    return (
        <div className="personal-details details-section" id="pd">
            <Heading>Personal Details</Heading>

            <div className="information row">
                <div>
                    <div className="name">{name}</div>
                    <div className="basic-info">
                        <table className="info-table">
                            <tbody>
                                <tr className="info-table-row">
                                    <td className="field">Designation:</td>
                                    <td className="value">{designation}</td>
                                </tr>
                                <tr className="info-table-row">
                                    <td className="field">Qualification:</td>
                                    <td className="value">{qualification}</td>
                                </tr>
                                <tr className="info-table-row">
                                    <td className="field">Experience:</td>
                                    <td className="value">{experience}</td>
                                </tr>
                                <tr className="info-table-row">
                                    <td className="field">Area of Interest:</td>
                                    <td className="value">
                                        {area_of_interest}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
};
export default PersonalDetails;
