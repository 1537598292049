import Layout from "components/layout/layout";
import DataProvider from "hoc/data_provider";
import ClubCard from "./clubCard";
import "./clubs.css";

const Clubs = () => {
    return <DataProvider endPoint="/web/clubs" component={ClubComponent} />;
};

export default Clubs;

const ClubComponent = ({ data }) => {
    return (
        <div className="clubs">
            <Layout
                title="Clubs"
                breadcrumb={[
                    { name: "Home", path: "/" },
                    {
                        name: "Clubs",
                    },
                ]}
            >
                <div className="wrapper">
                    <div className="clubs_contain">
                        {data.map((club) => {
                            return <ClubCard key={club._id} club={club} />;
                        })}
                    </div>
                </div>
            </Layout>
        </div>
    );
};
