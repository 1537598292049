import React from "react";
import "./placement-styles/about-tnp.css";
import DataProvider from "hoc/data_provider";
import Sidebar from "components/placement/sidebar/sidebar";
import Carousel from "react-elastic-carousel";
import Layout from "components/layout/layout";
import Heading from "components/heading/Heading";
import axios from "axios";

const AboutTNP = () => {
    return <DataProvider component={TNP} endPoint="/web/placements/" />;
};
export default AboutTNP;

const TNP = ({ data }) => {
    const { tnp, officers, testimonials } = data;
    const tpos = officers.filter((officer) => {
        return officer.designation === "tpo";
    });

    const extras = officers.filter((officer) => {
        return officer.designation !== "tpo";
    });
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 3 },
    ];
    return (
        <Layout
            title="Training and Placement Cell"
            breadcrumb={[
                {
                    name: "Home",
                    path: "/",
                },
                {
                    name: "Training and Placement Cell",
                },
            ]}
        >
            <div className="for-division">
                <div className="content">
                    <div>
                        <Heading>About T&P</Heading>
                        <p className="sub-content">{tnp.about}</p>
                    </div>
                    <div className="user-testimonials">
                        <Carousel breakPoints={breakPoints}>
                            {testimonials.map((item) => {
                                let img;
                                if (item.image) {
                                    img = `${axios.defaults.baseURL}/download/profiles/${item.image}`;
                                }
                                return (
                                    <div
                                        className="carousel-item"
                                        key={item._id}
                                    >
                                        <div className="carousel-left">
                                            <div className="carousel-img">
                                                <img src={img} alt="student" />
                                            </div>
                                            <div className="carousel-name">
                                                <strong>{item.name}</strong>
                                            </div>
                                            <div className="carousel-batch">
                                                {item.department}{" "}
                                                {item.placedYear}
                                            </div>
                                        </div>
                                        <div className="carousel-right">
                                            <p>
                                                <i>"{item.message}"</i>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                    <div>
                        <Heading>Training & Placement Officer</Heading>

                        <div className="sub-content">
                            {tpos.map((tpo) => {
                                return (
                                    <TNPOfficer officer={tpo} key={tpo._id} />
                                );
                            })}
                        </div>
                    </div>
                    <div>
                        <Heading>Training & Placement Team</Heading>

                        <div className="sub-content">
                            {extras.map((tpo) => {
                                return (
                                    <TNPOfficer key={tpo._id} officer={tpo} />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <Sidebar />
            </div>
        </Layout>
    );
};

const TNPOfficer = ({ officer }) => {
    return (
        <div className="tnp-officer">
            <div className="officer-info">
                <h5 className="name">{officer.name}</h5>
                <b>{officer.designation.toUpperCase()}</b>
            </div>
            <div className="officer-mail">
                <i
                    className="d-inline-block g-width-20 fa fa-envelope"
                    style={{ marginRight: "1vw" }}
                ></i>
                :
                <a
                    href={"mailto:" + officer.email}
                    style={{ marginLeft: "1vw" }}
                >
                    {officer.email}
                </a>
            </div>
            <div className="officer-mail">
                <i
                    className="d-inline-block g-width-20 fa fa-phone"
                    style={{ marginRight: "1vw" }}
                ></i>
                :
                <a href={"tel:" + officer.number} style={{ marginLeft: "1vw" }}>
                    {officer.number}
                </a>
            </div>
        </div>
    );
};
