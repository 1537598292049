import Heading from "components/heading/Heading";
import { marked } from "helpers/helpers";

const StudyMaterial = ({ data }) => {
  return (
    <div className="publications details-section" id="study_materials">
      <Heading>Study Materials</Heading>
      <div className="studyInformation">
        {
          (data.length === 0) ? (<p>study materials not available</p>) :
            (data.map((v, i) => {
              return (
                <>
                  <div className="studyContainer">
                    <h5 dangerouslySetInnerHTML={marked(v.title)}></h5>
                    <p dangerouslySetInnerHTML={marked(v.description)}></p>
                    <a className="info-list-item" href={v.link} target="_blank">Get study materials</a>
                  </div>
                </>
              );
            }))}
      </div>
    </div>
  );
};
export default StudyMaterial;
