import React, { useEffect, useRef } from "react";

import "./ScrollToTop.css";

const ScrollToTop = () => {
    const scrollBtn = useRef(null);

    const makeVisible = () => {
        if (window.scrollY > 350 && scrollBtn) {
            scrollBtn.current.style.display = "block";
        } else {
            scrollBtn.current.style.display = "none";
        }
    };

    const scrollToTop = () => {
        const scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, scrollTop - scrollTop / 8);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", makeVisible);

        return () => {
            return window.removeEventListener("scroll", makeVisible);
        };
    }, []);

    return (
        <div
            className="scroll-to-top"
            id="scrollTop"
            ref={scrollBtn}
            onClick={scrollToTop}
        >
            <div>
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
            </div>
        </div>
    );
};

export default ScrollToTop;
