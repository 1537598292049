import GoogleApiWrapper from "components/CutomMap";
import "./contact_us.css";

const ContactUsPage = () => {
    const MyMap = GoogleApiWrapper;

    return (
        <div className="contact-us-page column">
            <div className="map-view">
                <MyMap />
            </div>

            <div className="info-form row">
                <div className="form-wrapper">
                    <div className="form-heading">DROP A LINE</div>
                    <form action="#" className="msg-form row">
                            <div className="msg-form-field">
                                <label htmlFor="msg-name">Your name</label>
                                <input type="text" id="msg-name" required />
                            </div>
                            <div className="msg-form-field">
                                <label htmlFor="msg-email">Your email</label>
                                <input type="text" id="msg-email" required />
                            </div>
                            <div className="msg-form-field">
                                <label htmlFor="msg-web">Your website</label>
                                <input type="text" id="msg-web" />
                            </div>
                            <div className="msg-form-field">
                                <label htmlFor="msg-title">Your title</label>
                                <input type="text" id="msg-title" />
                            </div>
                            <div className="msg-form-field">
                                <label htmlFor="msg-msg">Your message</label>
                                <textarea
                                    type="text"
                                    id="msg-msg"
                                    required
                                    rows={5}
                                />
                            </div>
                            <div className="msg-form-field">
                                <input type="button" value="SEND MESSAGE" />
                            </div>
                    </form>
                </div>
                <div className="form-wrapper">
                    <div className="form-heading">CONTACT INFO</div>
                    <div className="details-wrapper">
                        <a href="#/" className="contact-details row">
                            <div className="icn"></div>
                            <div className="details">
                                <div className="title">
                                    GOVERNMENT ENGINEERING COLLEGE, BHAVNAGAR
                                </div>
                                <div className="descrip">
                                    Government Engineering College, Nr. BPTI,
                                    Vidyanagar, Bhavnagar, Gujarat 364002, India.
                                </div>
                            </div>
                        </a>
                        <a href="tel:2782525354" className="contact-details">
                            <div className="icn"></div>
                            <div className="details">
                                <div className="title">CONTACT NO.</div>
                                <div className="descrip">0278-2782525354</div>
                            </div>
                        </a>{" "}
                        <a
                            href="e-mail:gec-bhav-dte@gujarat.gov.in"
                            className="contact-details"
                        >
                            <div className="icn"></div>
                            <div className="details">
                                <div className="title">EMAIL</div>
                                <div className="descrip">
                                    gec-bhav-dte@gujarat.gov.in
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
