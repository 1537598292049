import Heading from "components/heading/Heading";

const CourseTaught = ({ data }) => {
    return (
        <div className="course-taught details-section" id="course">
            <Heading>Course Taught</Heading>
            <div className="infomration">
                <ol>
                    {data ? (
                        data.map((v, i) => {
                            return (
                                <li className="info-list-item" key={i}>
                                    <p>{v}</p>
                                </li>
                            );
                        })
                    ) : (
                        <li className="info-list-item" key="no-data">
                            <p>No Data Available</p>
                        </li>
                    )}
                </ol>
            </div>
        </div>
    );
};

export default CourseTaught;
