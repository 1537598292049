import "./coe.css";
import { Carousel } from "react-responsive-carousel";
import Layout from "components/layout/layout";

const CoEPage = () => {
    const labs = [
        {
            title: "Product Design and Validation Lab",
            description: `List of Machines:  Qty. - 30 1) Dell Precision T1700 Computer Workstations.Specifications: 
3.30 gigahertz Intel Xeon E3-1226 v3 Processor, Dell Inc.073MMW A02 Mother Board, NVIDIA Quadro K600 Graphics Card, 16Gb RAM, 500GB Hard Disk, DELL E2016t Monitor, DELL KB212-B Keyboard, DELL MS111-P Mouse, Windows 7 Professional (x64) Service Pack 1 OS, Microsoft - Office Standard 2013.
NX 9.0`,
            images: [
                "/assets/coe/coelab1.jpg",
            ],
        },
        {
            title: "Advanced Manufacturing Lab",
            description: `List of Machines:  Qty. - 30
1) Dell Precision T1700 Computer Workstations.
Specifications : 
3.30 gigahertz Intel Xeon E3-1226 v3 Processor, Dell Inc.073MMW A02 Mother Board, NVIDIA Quadro K600 Graphics Card, 16Gb RAM, 500GB Hard Disk, DELL E2016t Monitor, DELL KB212-B Keyboard, DELL MS111-P Mouse, Windows 7 Professional (x64) Service Pack 1 OS, Microsoft - Office Standard 2013.
`,
            images: [
                "/assets/coe/coelab2.jpg",
            ],
        },
	{
            title: "CNC Programming Lab",
            description: `List of Machines: 
1) Sinumerik Turning - 808D 
(2 Qty.) 
2) Sinumerik Milling - 808D
(2 Qty.) 
3) Sinumerik - 840D (1 Qty.)
With 5 nos. Dell Precision T1700 Computer Workstations.
`,
            images: [
                "/assets/coe/coelab3.jpg",
            ],
        },
        {
            title: "Automation Lab",
            description: `List of Machines: 
1) Simatic S7-500 (7 Qty.)
2) Simatic S7-1300
With 7 nos. Dell Precision T1700 Computer Workstations.
`,
            images: [
                "/assets/coe/coelab4.jpg",
            ],
        },
	{
            title: "Mechatronics Lab",
            description: `List of Machines: 
1) Assembly workstation (1 Qty.) 
2) Pneumatic Press (6 Qty.)
3) Vibration Free Compressor 
(2 Qty.)
With 7 nos. Dell Precision T1700 Computer Workstations.
`,
            images: [
                "/assets/coe/coelab5.jpg",
            ],
        },
        {
            title: "Electrical Lab",
            description: `List of Machines: 
1) 3WT8 Air Circuit Breaker 
(2 Qty.)
2) Simcode Pro (1 Qty.)
3) Sinamics G-120 Demo kit 
(5 Qty.)
4) Sinamics DC Master Drive Training Kit (2 Qty.)
With 8 nos. Dell Precision T1700 Computer Workstations.
`,
            images: [
                "/assets/coe/coelab6.jpg",
            ],
        },
	{
            title: "Process Instrumentation Lab",
            description: `List of Machines: 
1) Simatic PCS 7 Training kit 
(2 Qty.) 
2) Process Instrumentation Training Kits.
With 6 nos. Dell Precision T1700 Computer Workstations.
`,
            images: [
                "/assets/coe/coelab7.jpg",
            ],
        },
        {
            title: "Computer Integrated Manufacturing (CIM) Lab",
            description: `List of Machines: 
1) Flexturn with gantry loader and Transfer conveyor
2) 6 Axis Aristo Robot.
3) Automatic storage and Retrieval System.
With 3 nos. Dell Precision T1700 Computer Workstations.
MTAB, MROBOT`
,
            images: [
                "/assets/coe/coelab8.jpg",
            ],
        },
        {
            title: "Rapid Prototyping (RPT) Lab",
            description: `List of Machines: 
1) Stratasys Dimension SST-1200es (1 Qty.)
With 1 nos. Dell Precision T1700 Computer Workstations.
`,
            images: [
                "/assets/coe/coelab9.jpg",
            ],
        },
    ];

    return (
        <Layout
            title="Center of Excellence"
            breadcrumb={[
                { name: "Home", path: "/" },
                { name: "Center of Excellence" },
            ]}
        >
            <div className="coe-page">
                <div className="coe-page-data">
                    <div className="coe-about">
                        <h3 className="coe-info-title">About Center of Excellence</h3>
                        <div>
                            <p>A Strategic Initiative Skills and knowledge are the driving forces of economic growth and social development for any country. An economy like India requires a large and skilled workforce to achieve the ambitious plans of Government of India (GoI) and to raise the share of manufacturing from the current GDP of 16 percent to 25 percent by 2025. This goal can be achieved only when the companies adopt high end and innovative technologies. However, the availability of limited skilled workforce creates a roadblock in the adoption of newer technologies. Subsequently, a gap exists between the growing demand for and the scarce supply of skilled personnel across sectors. This gap, if not addressed quickly, will have a strong bearing on the productivity and competitiveness of the sector itself.</p>
<br/>                            <p>To fill this gap, Government of Gujarat has sanctioned Siemens Center of Excellence in Ship Building at Government Engineering College, Bhavnagar and Siemens - Centre of Excellence has been established as per MOU between:</p>
<br/>                            <ul>
                                <li>Industries Commissionerate, Government of Gujarat,</li>
                                <li>Siemens Industry Software (INDIA) Pvt Ltd.,</li>
                                <li>DesignTech Systems Ltd. and</li>
                                <li>Government Engineering College, Bhavnagar</li>
                            </ul><br/>
                            <p>CoE has established relevant laboratories having Siemens products and Siemens Industry software applications. The equipments which have been provided by Siemens are of state-of-the-art level. The laboratories which have been established at Government Engineering College, Bhavnagar under CoE, are as follows:</p>
<br/>                        <ol>
                                <li>Product Design and Validation Lab</li>
                                <li>Advanced Manufacturing Lab</li>
                                <li>Automation Lab</li>
                                <li>Electrical Lab</li>
                                <li>Mechatronics Lab</li>
                                <li>Process Control & Instrumentation Lab</li>
                                <li>CNC Programming Lab</li>
                                <li>Rapid Prototyping Lab</li>
                                <li>CIM Simulation Lab</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="coe-labs" id="labs">
                    <h3 className="coe-info-title">Laboratories</h3>
                    <div className="coe-labs-list">
                        {labs.map((lab, idx) => (
                            <LabCard key={idx} lab={lab} />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const LabCard = ({ lab }) => {
    console.log('Rendering LabCard with lab:', lab);
    const { title, description, images } = lab;

    return (
        <div className="coe-lab-card">
            <Carousel
                className="lab-gallery"
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showIndicators={false}
            >
                {images.map((image, idx) => (
                    <div className="lab_image" key={idx}>
                        <img src={image} alt="lab-imgs" />
                    </div>
                ))}
            </Carousel>
            <div className="lab-info">
                <h5 className="lab-title">{title}</h5>
                <p className="lab-desc">{description}</p>
            </div>
        </div>
    );
};

export default CoEPage;

