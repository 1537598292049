import "./underConstruction.css";

const UnderConstruction = () => {
  return (
    <div className="underConstruction">
      <div className="lottie_contain">
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_uiim3rtu.json"
          background="transparent"
          speed="1"
          style={{ maxWidth: "500px", maxHeight: "500px"}}
          loop
          autoplay
        ></lottie-player>
      </div>
      <h2>Under Construction</h2>
    </div>
  );
};

export default UnderConstruction;
