import Heading from "components/heading/Heading";

const TrainingAndWorkshop = ({ data }) => {
    return (
        <div className="t_w details-section" id="t_w">
            <Heading>Training and Workshop</Heading>
            <div className="information">

                <div className="table-wrapper">

                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Sr No.</th>

                                <th>
                                    <strong>Title</strong>
                                </th>
                                <th>
                                    <strong>Venue</strong>
                                </th>
                                <th>
                                    <strong>Start Date</strong>
                                </th>
                                <th>
                                    <strong>End Date</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((v, i) => {
                                return (
                                    <tr key={v}>
                                        <td>{i + 1}</td>
                                        <td>{v.title}</td>
                                        <td>{v.venue}</td>
                                        <td>{v.start_date == null ? "NA" : v.start_date.substring(0, 10)}</td>
                                        <td>{v.end_date == null ? "NA" : v.end_date.substring(0, 10)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default TrainingAndWorkshop;
