import React, { useState } from "react";
import "components/navbar/navbar.css";
import "../../pages/home_page/home_page.css";
import logo from "assets/logooo.png";
import emblem from "assets/emblem.png";

import { Link } from "react-router-dom";

import { studentSectionItems } from "../../helpers/constants";

import UseAnimations from "react-useanimations";
import menu from "react-useanimations/lib/menu2";

const StudentNav = () => {
    return (
        <div className="home-page">
            <header className="header">
                <div className="head">
                    <div className="head__logo" to="/">
                        <Link to="/" className="head__link">
                            <img
                                src={logo}
                                alt="gec-bvn"
                                className="head__img"
                            />
                            <div>
                                <h1 className="name_title">GOVERNMENT ENGINEERING COLLEGE,</h1>
                                <h1 className="name_title">BHAVNAGAR</h1>
                                <p className="sub_tagline">Where Expectations Meets Opportunity</p>
                            </div>
                        </Link>
                    </div>

                    <div className="head__emblem">
                        <img src={emblem} alt="emblem" className="head__img" />
                    </div>
                </div>

                <NavbarTablet />
            </header>
            <nav className="navbar ">
                <ul className="navbar__list">
                    {studentSectionItems.map((item) => {
                        return <NavbarItem key={item.title} item={item} />;
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default StudentNav;

const NavbarTablet = () => {
    const [navState, setNavState] = useState("collapse");

    const toggleNav = () => {
        if (navState === "collapse") {
            setNavState("expand");
        } else {
            setNavState("collapse");
        }
    };

    const collapseNav = () => {
        setNavState("collapse");
    };

    return (
        <nav className="navbar-tab ">
            <button className="navbar-toggler" onClick={toggleNav}>
                <UseAnimations
                    animation={menu}
                    reverse={navState !== "collapse"}
                    size={35}
                    strokeColor="#fff"
                />
                <span className="navbar-toggler__text">MENU</span>
            </button>
            <div className={`navbar-collapse ${navState}`}>
                <ul className="navbar-tab__list">
                    {studentSectionItems.map((item) => {
                        return (
                            <NavbarTabletItem
                                key={item.title}
                                item={item}
                                collapseNav={collapseNav}
                            />
                        );
                    })}
                </ul>
            </div>
        </nav>
    );
};

const NavbarTabletItem = ({ item, collapseNav }) => {
    const [navState, setNavState] = useState("collapse");

    const toggleNav = () => {
        if (navState === "collapse") {
            setNavState("expand");
        } else {
            setNavState("collapse");
        }
    };

    return (
        <li className="navbar-tab__item">
            {item.link ? (
                <Link
                    to={item.link}
                    className="navbar-tab__link"
                    onClick={collapseNav}
                >
                    {item.title}
                </Link>
            ) : (
                <div className="navbar-tab__link" onClick={toggleNav}>
                    {item.title}
                    {item.dropdown && <i className="fas fa-chevron-down"></i>}
                </div>
            )}
            <div className={`navbar-tab__dropdown-collapse ${navState}`}>
                <div className="navbar-tab__dropdown">
                    {item.dropdown && (
                        <ul className="navbar-tab__dropdown-list">
                            {item.dropdown.map((drop, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="navbar-tab__dropdown-item"
                                    >
                                        <Link
                                            to={drop.link}
                                            className="navbar-tab__dropdown-link"
                                            onClick={collapseNav}
                                        >
                                            {drop.title}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </li>
    );
};

const NavbarItem = ({ item }) => {
    return (
        <li className="navbar__item">
            {item.link ? (
                <Link to={item.link} className="navbar__link">
                    {item.title}
                    {item.dropdown && <i className="fas fa-chevron-down"></i>}
                </Link>
            ) : (
                <div className="navbar__link">
                    {item.title}
                    {item.dropdown && <i className="fas fa-chevron-down"></i>}
                </div>
            )}
            <div className="navbar__dropdown">
                {item.dropdown && (
                    <ul className="navbar__dropdown-list">
                        {item.dropdown.map((drop, index) => {
                            return (
                                <li
                                    key={index}
                                    className="navbar__dropdown-item"
                                >
                                    <Link
                                        to={drop.link}
                                        className="navbar__dropdown-link"
                                        target={drop.target}
                                    >
                                        {drop.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </li>
    );
};
