import Heading from "components/heading/Heading";

const EducationalQualification = ({ data }) => {
    return (
        <div className="educational-qualification details-section" id="ed-qu">
            <Heading>Educational Qualification</Heading>
            <ul className="informationd">
                {data ? (
                    data.map((v, i) => {
                        return (
                            <li className="info-list-item" key={v}>
                                <p>{v}</p>
                            </li>
                        );
                    })
                ) : (
                    <p>No Data available</p>
                )}
            </ul>
        </div>
    );
};
export default EducationalQualification;
