import { useState } from "react";
import DataProvider from "hoc/data_provider";
import useMetadata from "hooks/useMetadata";
import { NavLink } from "react-router-dom";
import "./home_notice.css";

const HomeNotice = () => {
    const [divClass, setDivClass] = useState("hide-news");

    const toggleDivClass = () => {
        if (divClass === "hide-notice") {
            setDivClass("show-notice");
        } else {
            setDivClass("hide-notice");
        }
    };

    const hideNews = () => {
        setDivClass("hide-notice");
    };

    return (
        <div className={"home-notice row r-c-x r-c-y " + divClass}>
            <section className="news-section">
                <div className="header row r-c-y">
                    <section className="heading">
                        <h1>Notice Board</h1>
                    </section>
                    <div className="icon-btn close" onClick={hideNews}>
                        <i className="las la-times"></i>
                    </div>
                </div>
                <div className="news-data column">
                    <DataProvider component={NewsData} endPoint="/web/notice" />
                    <NavLink className="header-btn to-all" to={`/show/notice`}>
                    See All
                </NavLink>
                </div>
            </section>
            <div className="toggel-btn ">
                <div
                    className="btn-wrapper2 row r-c-x r-c-y"
                    onClick={toggleDivClass}
                >
                    <i className="las la-newspaper"></i>
                    <span>Notice</span>
                </div>
            </div>
        </div>
    );
};

export default HomeNotice;

const NewsData = ({ data }) => {

    const newsData = data.slice(0,10).map((news, idx) => {
        return (<NewsRow news={news} key={idx} />);
    });

    return <ul>{newsData}</ul>;
};

const NewsRow = ({ news }) => {
    let endPoint = null;

    const { proxy } = useMetadata();

    if (news.file) {
        endPoint = `${proxy}/download/files/${news.file}`;
    }

    return (
        <li className="news-row row r-c-y">
            <div className="news-data-wrapper">
                <a href={endPoint} className="data">
                    {news.title}
                </a>
                <p className="date-time">{news.date.substring(0, 10)}</p>
            </div>
        </li>
    );
};
